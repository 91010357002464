<template>
  <div>
    <div class="flex flex-wrap">
      <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
        <card-line-chart />
      </div>
      <div class="w-full xl:w-4/12 px-4">
        <card-bar-chart />
      </div>
    </div>
    <div class="flex flex-wrap mt-4">
      <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
        <card-page-visits />
      </div>
      <div class="w-full xl:w-4/12 px-4">
        <card-social-traffic />
      </div>
    </div>
  </div>
</template>

<script>

import CardLineChart from "@/components/Cards/CardLineChart.vue";
import CardBarChart from "@/components/Cards/CardBarChart.vue";
import CardPageVisits from "@/components/Cards/CardPageVisits.vue";
import CardSocialTraffic from "@/components/Cards/CardSocialTraffic.vue";

import data from '@/json/data.json';

export default {
  name: "dashboard-page",
  components: {
    CardLineChart,
    CardBarChart,
    CardPageVisits,
    CardSocialTraffic,
  },
  data() {
    return {
      data: data
    }
  },
  mounted() {
    // console.log(this.projid);
    // console.log(this.$route.params.id);
    if (localStorage.getItem('userid') == this.$route.params.id) {
      // console.log("Exists");
    }
    else {
      // console.log("Not exists");
      this.$router.push({ path: '/' });
      // this.$router.push({ path: '/admin/dashboard/' + this.$route.params.id, params: { id: this.$route.params.id } });
      // this.$router.go({ path: '/admin/dashboard/' + localStorage.getItem('userid') });
    }

    if ((!localStorage.getItem('step3')) || localStorage.getItem('step3') == null) {
      console.log(localStorage.getItem('step3'));
      // this.$router.push({ path: '/project/' + this.$route.params.id + '/install/step-2' });
    }
    else {
      // console.log(localStorage.getItem('step1'));
      console.log(localStorage.getItem('step3'), " - From Step 3");
    }

    localStorage.setItem('dashboard', true);
  },
};
</script>
