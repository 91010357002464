<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
      <card-new-activity title="Nuova attività" />
      <!-- <card-activity title="Nuova attività" /> -->
    </div>
    <div class="w-full mb-12 px-4">
      <card-activity title="Tutte le attività" />
    </div>
  </div>
</template>

<script>
import CardNewActivity from "@/components/Cards/CardNewActivity.vue";
import CardActivity from "@/components/Cards/CardActivity.vue";

export default {
  name: 'Activity',
  components: {
    CardActivity,
    CardNewActivity
  },
};
</script>
