<template>
  <div class="w-screen h-screen flex justify-center items-center">
    
    <section class="header items-center justify-center flex flex-col lg:flex-row h-screen max-h-860-px">
      <div class="items-center flex flex-wrap justify-end">
        <div class="w-full px-4">
          <div class="pt-32 sm:pt-0 flex flex-col">
            <h2 class="font-semibold text-4xl text-blueGray-600">Progetto: <span class="font-bold text-orange-500">{{ data.assets.name }}</span></h2>
            <p class="mt-4 text-lg leading-relaxed text-blueGray-500" v-html="data.assets.description"></p>
            <div class="mt-12 flex items-center">
              <a class="text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-orange-500 active:bg-emerald-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150 max-w-fit" href="#" @click.prevent="go">Prosegui <i class="fas fa-arrow-right ml-2"></i></a>
              <!-- <router-link class="text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-orange-500 active:bg-emerald-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150 max-w-fit" to="/install/step-1">Prosegui <i class="fas fa-arrow-right ml-2"></i></router-link> -->
            </div>
          </div>
        </div>
      </div>
      <img class="sm:w-6/12 sm:mt-0 w-10/12 max-h-860-px hidden md:flex" :src="patternVue" alt="..."/>
    </section>
    
  </div>
</template>

<script>

import "@fortawesome/fontawesome-free/css/all.min.css";
import patternVue from "@/assets/img/pattern_vue.png";

import data from '@/json/data.json';

export default {
  name: 'Project',
  data() {
    return {
      patternVue,
      i: 0,
      data: data.data[this.$route.params.id - 1],
    };
  },
  mounted() {
    // console.log(this.data);
    // console.log(localStorage.getItem('userid'));
    // console.log(this.$route.params.id);
    if (localStorage.getItem('userid') == this.$route.params.id) {
      // console.log("Exists");
    }
    else {
      // console.log("Not exists");
      this.$router.push({ path: '/' });
    }
  },
  methods: {
    go() {
      this.$router.push({ path: '/project/' + this.data.id + '/install/step-1' });
    }
  },
  components: {
    
  },
};
</script>

<style>
/* #myProgress {
  width: 100%;
  background-color: grey;
} */

.header {
  width: 80%;
}

.disabled {
  pointer-events: none;
  opacity: .4;
}

#get-done {
  max-width: fit-content;
}

#myBar {
  width: 1%;
  height: 12px;
  /* background-color: green; */
}
</style>
